import React from "react";

import Layout from "components/Layout";
import SEO from "components/seo";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <SEO
        keywords={[
          `contactless`,
          `payments`,
          `fintech`,
          `India`,
          `UPoS`,
          `Uvik Technologies`
        ]}
      />
      <div className="pt-24 lg:pb-32 pb-16 px-6 mt-8 page">
        <div className="max-w-6xl mx-auto">
          <h1>Privacy Policy for Uvik Technologies PVT LTD</h1>

          <p>
            At www.uvik.in, accessible from https://www.uvik.in, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by www.uvik.in and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in www.uvik.in. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>

          <h2>Overview</h2>

          <p>
            Uvik obtains Personal Data about you from various sources to provide
            our Services and to manage our solution to make a better user
            experience. Personally, identifiable information may include, but is
            not limited to, your email address, other information
            (&quot;Personal Information&quot;).
          </p>

          <h2>Log Data</h2>

          <p>
            We collect information that your browser sends whenever you visit
            our Service (&quot;Log Data&quot;). This Log Data may include
            information such as your computer&#39;s Internet Protocol
            (&quot;IP&quot;) address, browser type, browser version, the pages
            of our Service that you visit, the time and date of your visit, the
            time spent on those pages and other statistics. In addition, we may
            use third party services such as Google Analytics that collect,
            monitor and analyze this type of information in order to increase
            our Service&#39;s functionality. These third party service providers
            have their own privacy policies addressing how they use such
            information.
          </p>

          <h2>Cookies</h2>

          <p>
            Cookies are files with small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and stored on your computer&#39;s hard drive.We use
            &quot;cookies&quot; to collect information. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service.
          </p>

          <h2>Service Providers</h2>

          <p>
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services or to assist us in analyzing how our
            Service is used. These third parties have access to your Personal
            Information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>

          <h2>Communications</h2>

          <p>
            We may use your Personal Information to contact you with
            newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
          </p>

          <h2>Security</h2>

          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>

          <h2>International Transfer</h2>

          <p>
            Your information, including Personal Information, may be transferred
            to and maintained on computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction. If you
            are located outside India and choose to provide information to us,
            please note that we transfer the information, including Personal
            Information, to India and process it there. Your consent to this
            Privacy Policy followed by your submission of such information
            represents your agreement to that transfer.
          </p>

          <h2>Links To Other Sites</h2>

          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party&#39;s site. We strongly advise you to review the
            Privacy Policy of every site you visit. We have no control over, and
            assume no responsibility for the content, privacy policies or
            practices of any third party sites or services.
          </p>

          <h2>Children&#39;s Privacy</h2>

          <p>
            <strong>
              Our Service does not address anyone under the age of 13
              (&quot;Children&quot;).
            </strong>{" "}
            We do not knowingly collect personally identifiable information from
            children under 13. If you are a parent or guardian and you are aware
            that your Children has provided us with Personal Information, please
            contact us. If we become aware that we have collected Personal
            Information from a children under age 13 without verification of
            parental consent, we take steps to remove that information from our
            serveOur Service may contain links to other sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party&#39;s site. We strongly advise you to
            review the Privacy Policy of every site you visit. We have no
            control over, and assume no responsibility for the content, privacy
            policies or practices of any third party sites or services.rs.
          </p>

          <h2>Changes To This Privacy Policy</h2>

          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this
            page.You are advised to review this Privacy Policy periodically for
            any changes. Changes to this Privacy Policy are effective when they
            are posted on this page.
          </p>

          <p>
            By using Uvik and/or by providing your information, you consent to
            the collection and use of the information you disclose on Uvik in
            accordance with this Privacy Policy, including but not limited to
            your consent for sharing your information as per this privacy
            policy. As per Information Technology Act 2000 and rules made there
            under, the name and contact details of the Grievance Officer are
            provided below:
          </p>

          <p>
            <strong>Mr. Binu George</strong> <br />
            #677, 1st Floor, <br />
            13th Cross Road, 27th Main Rd, <br />
            1st Sector, HSR Layout <br />
            Bengaluru, Karnataka 560102 <br />
          </p>
          <p>Email: support@uvik.in</p>
        </div>
      </div>
    </Layout>
  );
}
